import React from "react";
import styled from "styled-components";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import bambooButton from "../../resources/img/question/bamboo-button.png";
import breakpoints from "../../styles/breakpoints";

const StyledButton = styled.button`
  background: url(${bambooButton}) no-repeat;
  background-size: auto 100%;
  border: 0;
  color: ${colors.black};
  ${fonts.gloryBold};
  font-size: 18px;
  border-radius: 0;
  text-align: center;
  width: 160px;
  height: 40px;
  line-height: 40px;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  &:hover {
    color: ${colors.darkGreen};
  }
  
  span {
    margin-top: -4px;
    padding-left: 4px;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 20px;
    width: 240px;
    height: 60px;
    line-height: 60px;
  }
`;

const Button = ({children, onClick, processing}) => {
  return (
    <StyledButton onClick={onClick} processing={processing}>
      <span>
        {children}
      </span>
    </StyledButton>
  );
};

export default Button;
