import React, {useEffect, useState} from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import styled from "styled-components"
import Container from "../components/Question/Container";
import colors from "../styles/colors";
import Logo from "../components/Question/Logo";
import Button from "../components/Question/Button";
import ConnectWallet from "../components/Question/ConnectWallet";
import forest from "../resources/img/background/hero-background.jpg";
import Text from "../components/Core/Text";
import storage from "../services/storage";
import Frame from "../components/Question/Frame";
import fonts from "../styles/fonts";
import QuestionTitle from "../components/Question/QuestionTitle";
import breakpoints from "../styles/breakpoints";

const PageBackground = styled.div`
  background: url(${forest}) no-repeat;
  background-size: cover;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: 100vh;
`;

const PageContent = styled.div`
  width: 100%;
  max-width: 1040px;
  padding: 0;
  margin: auto;
  text-align: center;
  min-height: 100vh;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
  position: relative;
  min-height: 420px;
`;

const HeaderContainer = styled.div`
  margin: 32px auto 12px auto;
  text-align: center;
  display: flex;
  align-item: center;
  justify-content: center;
  position: relative;
`;

const ButtonContainer = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 12px;
  
  button {
    margin: auto;
  }
`;

const ConnectContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  width: auto;
  margin-bottom: 40px;
`;

const Errors = styled.p`
  ${fonts.gloryBold};
  color: ${colors.darkGreen};
  margin-top: 12px;
  display: block;
  line-height: 20px;
  min-height: 24px;
`;

const DesktopOnly = styled.div`
  display: none;
  
  @media (min-width: ${breakpoints.sm}) {
    display: flex;
    flex-direction: column;
  }
`;

const MobileOnly = styled.div`
  display: flex;
  
  @media (min-width: ${breakpoints.sm}) {
    display: none;
  }
`;

const StartGamePage = ({location}) => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    const accountData = storage.getAccount();

    if (accountData && typeof accountData !== "undefined") {
      setWalletAddress(accountData);
    }
  }, []);

  const onConnect = (address) => {
    setWalletAddress(address);
    setHasErrors(false);
  };

  const startGame = async () => {
    if (!walletAddress) {
      setHasErrors(true);

      return;
    }

    if (hasErrors === false) {
      window.location.href = "https://pandadynasty.io/play";
    }

    window.setTimeout(function () {
      setHasErrors(false);
    }, 1600);

  };

  return (
    <Layout footer={"false"}>
      <Seo title={"Panda Dynasty Game"}
           description={"Panda Dynasty Game - Get Started!"}
           path={location.pathname}/>
      <PageBackground>
        <PageContent>
          <Container>
            <HeaderContainer>
              <Logo/>
              <DesktopOnly>
                <ConnectContainer>
                  <ConnectWallet callback={onConnect}/>
                </ConnectContainer>
              </DesktopOnly>
            </HeaderContainer>
            <Frame>
              <QuestionContainer>
                <DesktopOnly>
                  <QuestionTitle>Welcome to Panda Dynasty point & click game!<br/><br/></QuestionTitle>
                  <Text>To unlock the Panda Pass, you need to finish the game <br />and wait until the very end of the game after the credits.</Text>
                  <ButtonContainer>
                    <Button onClick={startGame}>
                      Start the game
                    </Button>
                  </ButtonContainer>
                  <Errors>{hasErrors && <>{walletAddress ? "An error occured! Please try again" : "Please connect your wallet!"}</>}</Errors>
                </DesktopOnly>
                <MobileOnly>
                  <QuestionTitle>Visit this page on a laptop or desktop screen to get started!</QuestionTitle>
                </MobileOnly>
              </QuestionContainer>
            </Frame>
          </Container>
        </PageContent>
      </PageBackground>
    </Layout>
  )
};

export default StartGamePage;
